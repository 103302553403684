<template>
  <div>
    <div v-if="loding">
    </div>
    <div v-else>
      <div :style="'height:'+ innerHeight + 'px;'">
        <div :style="{'background': background,'background-size': '100% 100%'}" class="bg"></div>
        <div class="e">
          <Card style="width:500px;border: 1px solid #eeeeee; " shadow bordered :title="pageInfo.site_title || '速速云签'">
            <div style="margin:20px 30px">
              <Form ref="formInline" :model="formInline" :rules="ruleInline" @keyup.enter="handleSubmit('formInline')">
                <FormItem prop="username">
                  <Input type="text" v-model="formInline.username" placeholder="请输入账户">
                    <Icon size="18" type="ios-person-outline" slot="prepend"></Icon>
                  </Input>
                </FormItem>
                <FormItem prop="password">
                  <Input type="password" v-model="formInline.password" placeholder="请输入密码">
                    <Icon size="18" type="ios-lock-outline" slot="prepend"></Icon>
                  </Input>
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('formInline')">登录</Button>
                </FormItem>
              </Form>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {AccountLogin, getLoginInfo} from '../../api/interFace'
  import {setCookies, removeCookies, parseQuery} from '@/utils/helper'
  // import '../../../assets/js/canvas-nest.min'

  export default {
    data() {
      return {
        background_image: require("@/assets/images/login_bg2.jpg"),
        background: require("@/assets/images/login_bg2.jpg"),
        innerHeight: 0,
        formInline: {
          uniacid_id:0,
          username: '',
          password: '',
          type:1  //0老版本   1新版本
        },
        ruleInline: {
          username: [
            {required: true, message: '账户不能为空', trigger: 'blur'}
          ],
          password: [
            {required: true, message: '密码不能为空', trigger: 'blur'}
          ]
        },
        params: {},
        pageInfo: {},
        loding:true
      }
    },
    created() { 
       console.log('4') 
      // let store_id = this.$route.query.store_id
      let store_id = "48389cd2b7f2c172bc9bcb2f6328171c"//正式
      // let store_id ="d1399774049e8f00132b3145b21f2c62" //测试服务器
      this.getOemInfo(store_id);
      this.params = parseQuery();
      
      var _this = this
      document.onkeydown = function () {
        if (_this.$route.name === 'login') {
          let key = window.event.keyCode
          if (key === 13) {
            _this.handleSubmit('formInline')
          }
        }
      }
    },
    mounted: function () {
      this.restHeight();
    },
    methods: {

      modifyIco(){
        localStorage.setItem(document.domain+'_companyInfo', JSON.stringify(this.pageInfo))
        window.document.title = this.pageInfo.site_title
        setCookies('platform_title', this.pageInfo.site_title)
        console.log("pageInfo", this.pageInfo)
        if(this.pageInfo.site_logo == ""){
          return;
        }
        let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = this.pageInfo.site_logo
        document.getElementsByTagName('head')[0].appendChild(link);
      },
      getOemInfo(store_id) {
        getLoginInfo({store_randomnum: store_id}).then(res => {
          this.pageInfo = res.data
          this.formInline.uniacid_id = res.data.uniacid_id
          this.background = 'url(' + res.data.login_bg + ') no-repeat' || require("@/assets/images/login_bg2.jpg");
          this.modifyIco();
          this.loding= false
        }).catch();
      },
      /**修改高度 */
      restHeight() {
        this.innerHeight = window.innerHeight - 2;
      },
      handleSubmit(name) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            this.login()
          } else {
            this.$Message.error('数据不能为空');
          }
        })
      },
      login() {
        let msg = this.$Message.loading({
          content: '登录中...',
          duration: 0
        })
        AccountLogin(this.formInline).then(res => {
          msg()

          let data = res.data
          if (data.userinfo.status == 0) {
            
            let test = window.location.href;
            localStorage.setItem("InitializeUrl",test) //登录的网站链接
            // 登陆信息
            setCookies('token', data.token)
            setCookies('expires_time', data.expires_time)
            setCookies('uuid', data.userinfo.id)
            setCookies('version', data.version_status)
            this.$router.replace({ path: '/index' })
            this.$store.commit('setMenus', data.menus)
            this.$store.commit('setAuth', data.unique_auth)
            this.$store.commit('setUserInfo', data.userinfo)
            this.$store.commit('setVersion', data.version_status)
            this.$store.commit('setIdentity', data.identity) //唐+ 身份识别
          } else {
            removeCookies('token')
            removeCookies('expires_time')
            this.$Message.error('您已被限制登录')
          }
        }).catch(res => {
          msg()
          console.log(res)
          let data = res === undefined ? {} : res
          this.$Message.error(data.msg || '登录失败')
        })
      }

    }
  }
</script>
<style scoped>
  .ivu-icon {
    width: 30px
  }

  .e {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 65%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
  }

  .bg {

    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  /deep/ .ivu-card-head {
    text-align: center;
  }

  /deep/ .ivu-card-head p, .ivu-card-head-inner {
    height: 35px !important;
    line-height: 35px !important;
    font-size: 30px !important;
  }
</style>
